import request from './http';
const baseUrl = 'https://roc.zhiqinxingchen.com';

export const submitCode = (data: any) =>
  request({
    url: baseUrl + '/message/user/submitPhone',
    method: 'post',
    data: data
  });

export const submitMsg = (data: any) =>
  request({
    url: baseUrl + '/message/user/submitMsg',
    method: 'post',
    data: data
  });

