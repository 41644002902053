







































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { submitCode, submitMsg } from '@/api/xinkebao';
import { areaList } from '@/Mock/wuliu.js';
import { codeList } from '@/Mock/zuzhi.js';
import { Toast, Dialog } from 'vant';
@Component({
  name: 'fill',
  components: {}
})
export default class extends Vue {
  private loading = false;
  private searchResult = [];
  private isShowArea = false;
  private isShowOwnership = false;
  private areaList = areaList;
  private codeList = codeList;
  private isView = false;
  private isShowAll = false;
  private pattern = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  private formData: any = {
    msg: '',
    certName: '',
    phone: '',
    certId: '',
    address: '',
    webProvince: '', // 省份
    webCity: '', // 市
    webCounty: '', // 区县
    essProvince: '', // 归属地省份
    essCity: '', // 归属地市
    essCounty: '', //归属地区/县
    area: '',
    ownership: '',
    channel: '',
    url: ''
  }
  private imgUrl: any = ''
  private validator (val: any) {
    return /^1(3|4|5|6|7|8|9)\d{9}$/.test(val);
  }
  @Watch('formData.phone', { immediate: true })
  private watchphone (val: any) {
    if (val && val.length === 11) {
      this.isShowAll = true;
    } else {
      this.isShowAll = false;
    }
  }
  private changeAreaValue (value: any) {
    let { selectedOptions } = value;
    this.formData.area = selectedOptions.map((option: any) => option.text).join('-');
    this.isShowArea = false;
  }
  private handleIsShowNotice () {
    Dialog.alert({
      title: '关于客户个人信息收集、使用规则的公告',
      message: '尊敬的客户： <br/>   如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。  为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：  <br/> (a)事先获得客户的明确授权； <br/> (b)根据有关的法律法规要求； <br/> (c)按照相关司法机关和/或政府主管部门的要求；<br/> (d)为维护社会公众的利益所必需且适当； <br/> (e)为维护我公司或客户的合法权益所必需且适当。 <br/> ',
    }).then(() => {
      // on close
    });
  }
  private onFailed (errorInfo: any) {
    console.log('error', errorInfo);
  }
  private async onSubmit () {
    let formdata = new FormData();
    formdata.append('phone', this.formData.phone);
    formdata.append('address', this.formData.area + '-' + this.formData.address);
    formdata.append('url', window.location.href || '');
    let res: any = await submitCode(formdata);
    if (res.status === 200) {
      Toast('提交成功,请查收验证码');
    }
  }
  private async handleSave () {
    if (!this.formData.msg) {
      Toast('请输入验证码');
      return false;
    } else {
      let formdata = new FormData();
      formdata.append('phone', this.formData.phone);
      formdata.append('msg', this.formData.msg);
      formdata.append('url', window.location.href || '');
      let res: any = await submitMsg(formdata);
      if (res.status === 200) {
        Toast('提交申请成功');
      }
    }
  }
}
